<template>
  <article class="about">
    <h2>OLTA</h2>
    <h3>Vision</h3>
    <p>OLTA is a platform to discover, interact and alter immersive art. Interactive NFT’s offer the role of participation with digital art. These new roles with Art can add value to an artist's concept, give ownership to audiences and bring exposure to the artist and art collector.</p>
    <p>We showcase artists who harness web technologies and celebrate coding as a creative medium. They make art that is interactive and experienced through a web browser. Artworks are available as NFT's.</p>
    <p>Code as a creative medium is changeable and adaptive. Imagine an art piece that evolves over time, reflecting the choices made by collectors, audiences and artists alike. A new approach to community artwork. How exciting would that be?</p>
    <h3>Audience</h3>
    <p>Audiences can view / interact as well as alter the artworks and most excitingly, they can opt to save their changes so that the next visitor views it as they left it, and so on and so on.</p>
    <h3>Artists</h3>
    <p>Olta offers interactive artists (or teams) full control over their work. We are open to different technologies which the artist uses. Olta aims to help artists by offering features, often out of the scope of a single piece, that they can harness. Sell your artworks and receive a percentage on secondary sales. We only take a commission on auctions.</p>
    <h3>Collectors</h3>
    <p>Art collectors get exclusive access to additional interactive artwork control and platform features. Artworks are available as NFT’s using the Zora protocol on Ethereum.</p>
  </article>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "About",
})
</script>

<style lang="scss" scoped>
  .about{
    padding: $margin;
    max-width: 600px;
  }
</style>